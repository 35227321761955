import { Environment as EnvironmentEnum } from "@gea/digital-ui-lib";

import { Environment } from "./model";
export const environment: Environment = {
    name: EnvironmentEnum.TEST,
    production: true,
    endpoint: "https://hrtconnectteset2.azurewebsites.net/api/",
    oAuth: {
        clientId: '1b272846-b527-4c4f-b79b-f344563ff678',
        authority: 'https://login.portal.tst.gea.com/geaidtst.onmicrosoft.com/b2c_1_signin_signup/',
        authorityDomain: 'login.portal.tst.gea.com',
        postLogoutRedirectURL: 'https://hrtconnect.apps.tst.gea.com/',
        redirectURL: 'https://hrtconnect.apps.tst.gea.com/',
        passwordResetAuthority: 'https://login.portal.tst.gea.com/geaidtst.onmicrosoft.com/B2C_1_RESET_PASSWORD_BY_EMAIL_VERIFICATION'
    },
    baseURL: 'https://apim-gea-test.azure-api.net/',
    interceptorURLs: ['https://apim-gea-test.azure-api.net/', 'https://hrtconnect.apps.tst.gea.com/'],
    storageURL: 'https://strgaccb2ctest.blob.core.windows.net/strg-container-b2-test/',
    localizationStorageURL: 'https://strgacci18nprod.blob.core.windows.net/strg-container-i18n-prod/',
    portal: {
        baseURL: 'https://hrtconnect.apps.tst.gea.com/',
        redirectURL: 'https://apim-gea-test.azure-api.net/',
    }
};
